
































































































































































import moment from 'moment';
import { Watch, Component, Prop, Vue } from 'vue-property-decorator';
import { default as LeaseEditModel } from '@/modules/Lease/Edit';
import DepotList, { IDepotList_Item } from '@/modules/Depot/List';
import PaymentTermList, { IPaymentTermList_Item } from '@/modules/PaymentTerm/List';
import CountryList, { ICountryList_Item } from '@/modules/Country/List';
import User, { IUser } from '@/modules/User';
import CurrencyList, { ICurrencyList_Item } from '@/modules/Currency/List';
import { ICustomerList_Item } from '@/modules/Customer/List';
import CustomerInfo from '@/modules/Customer/Info';
import { ICustomerProjectList_Item } from '@/modules/CustomerProject/List';
import CustomerProjectInfo from '@/modules/CustomerProject/Info';
import CustomerProjectEdit, { ICustomerProjectEdit } from '@/modules/CustomerProject/Edit';

@Component
export default class LeaseEdit extends Vue {
  @Prop(String) readonly id ? : string
  
  canEditLeases                   = false;
  isBusy                          = true;
  leaseEdit: LeaseEditModel       = new LeaseEditModel();
  referenceSuggestions: IUser[]   = [];
  customerSelectInvalidFeedback?  = null as string | null;
  customerAccountInvalidFeedback? = null as string | null;
  customerProjectSuggestions: CustomerProjectInfo[] = [];

  onCustomerCountryCodeChange(countryCode: string) {
    const country = this.leaseEdit.Countries.find(c => c.Value === countryCode);

    if (country)
      this.leaseEdit.CustomerCountry = country.Name;
  }

  onDeliveryCountryCodeChange(countryCode: string) {
    const country = this.leaseEdit.Countries.find(c => c.Value === countryCode);

    if (country)
      this.leaseEdit.DeliveryCountry = country.Name;
  }

  get customerProject() {
    return { ProjectNumber: this.leaseEdit.CustomerAccount + "" } as CustomerProjectInfo;
  }

  set customerProject(cp: CustomerProjectInfo) {
    this.setCustomerProject(cp?.ProjectNumber);
  }

  get isEdit() {
    return !!this.id;
  }

  get customerReference() {
    return {
      FullName: this.leaseEdit.CustomerReference,
    };
  }

  set customerReference(cr:any) {
    this.leaseEdit.CustomerReference = cr?.FullName ?? cr ?? "";
  }

  async onSubmit() {
    if (!this.canEditLeases)
      return;

    if (!this.leaseEdit.CustomerId) {
      this.customerSelectInvalidFeedback = this.$t('Please select customer');
      return;
    }

    if (this.leaseEdit.CustomerAccount)
      await this.saveCustomerProject();
    
    this.$emit('submit', this.leaseEdit);
  }

  async saveCustomerProject() {
    var customerProjectEdit = new CustomerProjectEdit({
      CustomerId: this.leaseEdit.CustomerId,
      ProjectNumber: this.leaseEdit.CustomerAccount,
      Name: this.leaseEdit.DeliveryName,
      Address: this.leaseEdit.DeliveryAddress,
      Address2: this.leaseEdit.DeliveryAddress2,
      PostalCode: this.leaseEdit.DeliveryPostalCode,
      PostalArea: this.leaseEdit.DeliveryPostalArea,
      CountryCode: this.leaseEdit.DeliveryCountryCode,
      Country: this.leaseEdit.DeliveryCountry,
      ContactName: this.leaseEdit.DeliveryContactName,
      Phone: this.leaseEdit.DeliveryTelephone,
    } as ICustomerProjectEdit);

    await customerProjectEdit.SaveUpdate();

    if (customerProjectEdit.Id)
      this.leaseEdit.CustomerProjectId = customerProjectEdit.Id;
  }

  async setCustomerProject(num?: string | null) {
    this.leaseEdit.CustomerProjectId = null;
    this.customerAccountInvalidFeedback = null;

    this.leaseEdit.CustomerAccount = num ?? "";

    if (!num || !this.leaseEdit.CustomerId) 
      return;

    var cp = await CustomerProjectInfo.Find(num, this.leaseEdit.CustomerId);

    if (!cp) 
      return;

    var otherLeases = await this.$store.state.hyrma.client.CustomerProject.GetProjectLeases(this.leaseEdit.CustomerId, cp.ProjectNumber, this.leaseEdit.Id);
    this.customerAccountInvalidFeedback = otherLeases.length ? this.$t("Already in use") + " " + this.$t("in") + " " + otherLeases.join(", ") : null;

    this.leaseEdit.CustomerProjectId   = cp.Id;
    this.leaseEdit.CustomerAccount     = cp.ProjectNumber;
    this.leaseEdit.DeliveryName        = cp.Name;
    this.leaseEdit.DeliveryAddress     = cp.Address;
    this.leaseEdit.DeliveryAddress2    = cp.Address2;
    this.leaseEdit.DeliveryPostalCode  = cp.PostalCode;
    this.leaseEdit.DeliveryPostalArea  = cp.PostalArea;
    this.leaseEdit.DeliveryCountryCode = cp.CountryCode;
    this.leaseEdit.DeliveryCountry     = cp.Country;
    this.customerReference             = cp.CustomerReference;
    
    if (cp.RentalInsurancePercent != null)
      this.leaseEdit.RentalInsurancePercent = cp.RentalInsurancePercent;
  }

  get customer(): ICustomerList_Item | null {
    if (!this.leaseEdit.CustomerId) return null;

    return {
      Id: this.leaseEdit.CustomerId,
      Number: this.leaseEdit.CustomerNumber,
      Name: this.leaseEdit.CustomerName
    };
  }

  set customer(cust: ICustomerList_Item | null) {
    this.customerSelectInvalidFeedback = null;

    if (!cust)
        return;

    this.setCustomer(cust.Id);
  }

  get referenceUser(): IUser | null {
    if (!this.leaseEdit.ReferenceUserId) return null;

    return {
      Id: this.leaseEdit.ReferenceUserId,
      FullName: this.leaseEdit.ReferenceUserText
    } as IUser;
  }

  set referenceUser(ru: IUser | null) {
    const newReferenceUser = ru || {} as IUser;

    this.leaseEdit.ReferenceUserId = newReferenceUser.Id;
    this.leaseEdit.ReferenceUserText = newReferenceUser.FullName;
  }

  async setCustomer(id: number) {
    const customerInfo = await CustomerInfo.Find(id);

    if (!customerInfo)
      return;

    this.leaseEdit.CustomerId             = customerInfo.Id;
    this.leaseEdit.CustomerNumber         = customerInfo.CustomerNumber;
    this.leaseEdit.CustomerName           = customerInfo.Name;
    this.leaseEdit.CustomerAddress        = customerInfo.Address;
    this.leaseEdit.CustomerAddress2       = customerInfo.Address2;
    this.leaseEdit.CustomerPostalCode     = customerInfo.PostalCode;
    this.leaseEdit.CustomerPostalArea     = customerInfo.PostalArea;
    this.leaseEdit.CustomerCountryCode    = customerInfo.CountryCode;
    this.leaseEdit.CustomerCountry        = customerInfo.Country;
    this.leaseEdit.CurrencyId             = this.leaseEdit.Currencies.find((c: any) => c.Text === customerInfo.CurrencyCode)?.Value ?? this.leaseEdit.CurrencyId;
    this.leaseEdit.CustomerTelephone      = customerInfo.Telephone;
    this.leaseEdit.RentalInsurancePercent = customerInfo.InsurancePercent;
    this.leaseEdit.CustomerAccount        = customerInfo.CustomerAccount;
    this.leaseEdit.DeliveryName           = customerInfo.DeliveryName;
    this.leaseEdit.DeliveryAddress        = customerInfo.DeliveryAddress;
    this.leaseEdit.DeliveryAddress2       = customerInfo.DeliveryAddress2;
    this.leaseEdit.DeliveryPostalCode     = customerInfo.DeliveryPostalCode;
    this.leaseEdit.DeliveryPostalArea     = customerInfo.DeliveryPostalArea;
    this.leaseEdit.DeliveryCountryCode    = customerInfo.DeliveryCountryCode;
    this.leaseEdit.DeliveryContactName    = customerInfo.CustomerReference;
    this.leaseEdit.PaymentTermId          = customerInfo.PaymentTerm;
    this.leaseEdit.VatNo                  = customerInfo.VatNo;
    this.leaseEdit.OrgNo                  = customerInfo.OrgNo;
    this.leaseEdit.IsContractCustomer     = customerInfo.IsContractCustomer;
    this.leaseEdit.CustomerProjectId      = null;
    this.customerAccountInvalidFeedback   = null;

    await this.fetchCustomerProjectSuggestions('');
  }

  async fetchCustomerProjectSuggestions(
    search: string,
    loading: Function | null = null
  ) {
    if (loading) loading(true);

    this.customerProjectSuggestions = await CustomerProjectInfo.Autocomplete(search, this.leaseEdit.CustomerId!) || [];
    
    if (loading) loading(false);
  }


  async fetchReferenceSuggestions(
    search: string,
    loading: Function | null = null
  ) {
    if (loading) loading(true);

    this.referenceSuggestions = await User.Autocomplete(search);

    if (loading) loading(false);
  }

  async fetchData() {
    this.isBusy = true;

    if (this.isEdit) {
      this.leaseEdit = await LeaseEditModel.Find(+this.id!) as LeaseEditModel;

      if (this.leaseEdit.ReferenceUserId) {
        this.referenceUser = {
          Id: this.leaseEdit.ReferenceUserId,
          FullName: this.leaseEdit.ReferenceUserText
        } as IUser;
      }

      if (this.leaseEdit.CustomerId)
        await this.fetchCustomerProjectSuggestions('');
    } else {     
      const depotList = await DepotList.FetchAll();
      this.leaseEdit.Depots = [
        {
          Text: "", 
          Value: ""
        }, 
        ...depotList.Items.map(
          (d: IDepotList_Item) => ({
            Text: d.Name,
            Value: d.Id
          })
        )
      ];

      if (!this.leaseEdit.DepotId) {
        const userDepot = await User.Depot();
        this.leaseEdit.DepotId = `${userDepot?.Id}` || this.leaseEdit.Depots[0].Value;
      }

      const paymentTermList = await PaymentTermList.FetchAll();
      this.leaseEdit.PaymentTerms = paymentTermList.Items.map(
        (pt: IPaymentTermList_Item) => ({
          Text: pt.Name,
          Value: pt.Id
        })
      );

      if (this.leaseEdit.PaymentTerms.length) {
        this.leaseEdit.PaymentTermId = this.leaseEdit.PaymentTerms[0].Value;
      }

      const currencyList = await CurrencyList.FetchAll();
      this.leaseEdit.Currencies = currencyList.Items.map(
        (c: ICurrencyList_Item) => ({
          Text: c.CurrencyCode,
          Value: c.Id,
        })
      );

      this.leaseEdit.CurrencyId = currencyList.CompanyCurrencyId;

      const countryList = await CountryList.FetchAll();
      this.leaseEdit.SetCountriesList(countryList.Items.map(
        (c: ICountryList_Item) => ({
          Text: c.Name,
          Value: c.TwoLetterCode
        })
      ));

      const userCompanyCountry = countryList.Items.find(c => c.TwoLetterCode === countryList.CompanyCountryCode);

      if (userCompanyCountry) {
        this.leaseEdit.CustomerCountryCode = userCompanyCountry.TwoLetterCode;
        this.leaseEdit.CustomerCountry = userCompanyCountry.Name;

        this.leaseEdit.DeliveryCountryCode = userCompanyCountry.TwoLetterCode;
        this.leaseEdit.DeliveryCountry = userCompanyCountry.Name;
      } else if (this.leaseEdit.Countries.length) {
        const {
          Name: countryName,
          Value: countryCode
        } = this.leaseEdit.Countries[0];
        this.leaseEdit.CustomerCountryCode = countryCode;
        this.leaseEdit.CustomerCountry = countryName;

        this.leaseEdit.DeliveryCountryCode = countryCode;
        this.leaseEdit.DeliveryCountry = countryName;
      }
    }

    await this.fetchReferenceSuggestions('');

    this.isBusy = false;
  }

  async mounted() {
    this.canEditLeases = await User.Can('EditLeases');

    await this.$nextTick();
    await this.fetchData();
  }
}
